import contract from "../contract/contract.json"
import Web3 from "web3";
import { useState, useEffect } from "react";
import * as s from "../styles/globalStyles";
import styled from "styled-components";


export const StyledButton = styled.button`
font-size: 20px;
border-radius: 40px;
padding: 1%;
display: block;
  margin-left: auto;
  margin-right: auto;
`;



const initialInfo = {
connected: false,
status: null,
account: null,
contract: null,
};

const initContractState = {
    _cost: null,
    _maxSupply: null,
    _hiddenMetadataUri: null,
    _owner: null,
    _revealed: null,
    _totalSupply: null,
    _uriPrefix: null,
    _paused: null,
    loading: false,
    isOwner: false,
    _totalbalance: null,
}



//console.log(contract);

const Admin =() => {

    const [info, setInfo] = useState(initialInfo);
    const [contractData , setContractData] = useState(initContractState);

    const init  = async () => {
        if (window.ethereum?.isMetaMask){
            const accounts = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            //console.log(accounts);
            const networkId = await window.ethereum.request({
                method: "net_version",
            });
            //console.log(networkId);
            if (networkId == 1){
                let web3 = new Web3(window.ethereum);
                setInfo({
                    ...initialInfo,
                    connected:true,
                    account: accounts[0],
                    contract: new web3.eth.Contract(contract.abi,  contract.address),
                });
                
            }else{
                //console.log("You need to be on the Ethereum testnet.");
                setInfo({...initialInfo, status: "You need to be on the Ethereum testnet."});
            }
        }
        else{
            setInfo({...initialInfo, status: "you need metamask"});
        }

    };

    

    const initOnchanged = () => {
        if (window.ethereum) {
            window.ethereum.on("accountsChanged",() =>{
                window.location.reload();
            });
            window.ethereum.on("chainChanged",() =>{
                window.location.reload();
            });
        }
    };

    

    const owner = async () => {
        setContractData(prevState => ({
            ...prevState,
            loading: true,
        }));

        let owner="";
        try{
        owner= await info.contract.methods.owner().call();
        }
        catch(error){
            
        }
            if (owner.toLowerCase() == window.ethereum.selectedAddress){
                let cost= await info.contract.methods.cost().call();
                let maxSupply= await info.contract.methods.maxSupply().call();
                let hiddenMetadataUri= await info.contract.methods.hiddenMetadataUri().call();
                let revealed= await info.contract.methods.revealed().call();
                let totalSupply= await info.contract.methods.totalSupply().call();
                let uriPrefix= await info.contract.methods.uriPrefix().call();
                let paused = await info.contract.methods.paused().call();
                let web3 = new Web3(window.ethereum);
                let totalbalance = await web3.eth.getBalance(contract.address);
                //console.log("Balance",totalbalance);
                //console.log("you are the owner", window.ethereum.selectedAddress);
                //console.log(info.contract.methods);
                setContractData({
                    ...contractData,
                    loading: false,
                    isOwner: true,
                    _cost: cost.toString(),
                    _maxSupply: maxSupply.toString(),
                    _hiddenMetadataUri: hiddenMetadataUri,
                    _owner: owner.toLowerCase(),
                    _revealed: revealed.toString(),
                    _totalSupply: totalSupply.toString(),
                    _uriPrefix: uriPrefix,
                    _paused: paused.toString(),
                    _totalbalance: totalbalance,
                });
            }else{
                console.log("you are not owner", window.ethereum.selectedAddress,owner.toLowerCase() );
                setContractData(initContractState);
            }

    };


    const pauseContract = async () => {
        try {
            document.getElementById("pop-pause-text").innerHTML = "Setting Pause State...";
            await info.contract.methods.setPaused(true).send({from: info.account});
            document.getElementById("pop-pause-text").innerHTML = "Pause State Set";
        } catch (error) {
            document.getElementById("pop-pause-text").innerHTML = "Transaction Rejected";
        }
    };

    const unPauseContract = async () => {
        try {
            document.getElementById("pop-pause-text").innerHTML = "Setting Un Pause State...";
            await info.contract.methods.setPaused(false).send({from: info.account});
            document.getElementById("pop-pause-text").innerHTML = "Un Pause State Set";
        } catch (error) {
            document.getElementById("pop-pause-text").innerHTML = "Transaction Rejected";
        }
    };

    const Withdraw = async () => {
        try {
            document.getElementById("pop-withdraw-text").innerHTML = "Withdrawing...";
            await info.contract.methods.withdraw().send({from: info.account});
            document.getElementById("pop-withdraw-text").innerHTML = "Withdrawn";
        } catch (error) {
            document.getElementById("pop-withdraw-text").innerHTML = "Transaction Rejected";
        }
    };

    const SetBaseUriContract = async () => {
        try {
            let set = document.getElementById("setBaseURI").value;

            document.getElementById("pop-baseUri-text").innerHTML = "Setting Base URI...";
            await info.contract.methods.setUriPrefix(set).send({from: info.account});
            document.getElementById("pop-baseUri-text").innerHTML = "Base URI Set.";
        } catch (error) {
            document.getElementById("pop-baseUri-text").innerHTML = "Transaction Rejected";
        }
    };
    
    const SetRevealed = async () => {
        try {
            document.getElementById("pop-revealed-text").innerHTML = "Revealing...";
            await info.contract.methods.setRevealed(true).send({from: info.account});
            document.getElementById("pop-revealed-text").innerHTML = "Revealed Done";
        } catch (error) {
            document.getElementById("pop-revealed-text").innerHTML = "Transaction Rejected";
        }
    };

    const SetCostContract = async () => {
        try {
            let set = document.getElementById("COST").value;
            //console.log
            document.getElementById("pop-Cost-text").innerHTML = "Setting New Cost...";
            await info.contract.methods.setCost(set).send({from: info.account});
            document.getElementById("pop-Cost-text").innerHTML = "New Cost Set.";
        } catch (error) {
            document.getElementById("pop-Cost-text").innerHTML = "Transaction Rejected";
        }
    };

    const airDropSubmit = async() => {
        try {
            let airdrop = document.getElementById("airdrop").value.toLowerCase();
            //console.log(airdrop);
            let airdropAmount = document.getElementById("airdropAmount").value;
            //console.log(airdropAmount);
            document.getElementById("pop-airdrop-text").innerHTML = "Air Droping...";
            await info.contract.methods.mintForAddress(airdropAmount , airdrop).send({from: info.account});
            document.getElementById("pop-airdrop-text").innerHTML = "Air drop succesfull";
        } catch (error) {
            document.getElementById("pop-airdrop-text").innerHTML = "Transaction Rejected";
        }
        
    };

    //console.log(info);

    useEffect(() => {
        init();
        initOnchanged();
    },[]);

    return(
        <div style = {
            {
                width: "100vw",
                overflowX: "hidden",
            }
        } >
        <div
        style = {
            {
                marginTop: "130px",
                paddingLeft:"5%",
                paddingRight:"5%",
                marginBottom:"15%",
            }
        } >
            <div  
            style = {
                {
                    borderStyle: "double",
                    padding:"5%",
                }
            }> 


            <StyledButton onClick={() => owner()}>Connect</StyledButton>
            <s.SpacerSmall />
            {contractData.loading ? <p>Loading...</p> : null}
            {contractData.isOwner ? <div>
            <s.TextTitle
              style={{ textAlign: "center", color: "var(--accent-text)" }}
            >
                Balance: {contractData._totalbalance/1000000000000000000} eth </s.TextTitle>

            <p>CONTRACT DATA</p>
            <p>Cost: {contractData._cost/1000000000000000000} eth</p>
            <p>Max Supply: {contractData._maxSupply} </p>
            <p>Not Revealed Uri: {contractData._hiddenMetadataUri} </p>
            <p>Owner: {contractData._owner} </p>
            <p>Revealed: {contractData._revealed} </p>
            <p>Total Supply: {contractData._totalSupply} </p>
            <p>Uri Prifix: {contractData._uriPrefix} </p>
            <p>Paused: {contractData._paused} </p>

            
            <s.SpacerLarge />
            <hr style={{
                width: "100%",
                border: "10px solid green",
                borderRadius: "5px",
            }}/>
            <s.SpacerLarge />
            <h1
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                  }}
                >
                  WITHDRAW
                </h1>
            <p id = "pop-withdraw-text" > </p> 
            <s.SpacerLarge />
            <StyledButton onClick={() => Withdraw()}>Withdraw</StyledButton>
            <s.SpacerLarge />
            <s.SpacerLarge />
            <hr style={{
                border: "10px solid green",
                borderRadius: "5px",
            }}/>
            <s.SpacerLarge />
            <h1
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                  }}
                >
                  PAUSE/UNPAUSE
                </h1>
            <p id = "pop-pause-text" > </p> 
            <s.SpacerLarge />
            <StyledButton  onClick={() => pauseContract()}>Pause Contract </StyledButton>
            <StyledButton onClick={() => unPauseContract()}>UnPause Contract</StyledButton>
            

            <s.SpacerLarge />
            <s.SpacerLarge />
            <hr style={{
                border: "10px solid green",
                borderRadius: "5px",
            }}/>
            <s.SpacerLarge />
            <h1
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                  }}
                >
                  AIR DROP
                </h1>
            <p id = "pop-airdrop-text" > </p> 
            <s.SpacerLarge />
            <div >
            <input
            id = "airdrop" 
                        style={{
                            width: "50%",
                            padding: "12px 20px",
                            margin: "8px 0",
                            marginLeft: "25%",
                            boxSizing: "border-box",
                            borderRadius: "40px",
                          }}
                        placeholder="WALLET ADDRESS"
                        />
                        <s.SpacerSmall />
                        <input
                type="number"
                id = "airdropAmount" 
                        style={{
                            width: "20%",
                            padding: "12px 20px",
                            margin: "8px 0",
                            marginLeft: "40%",
                            boxSizing: "border-box",
                            borderRadius: "40px",
                          }}
                        placeholder="AirDrop Amount"
                        />
                        <s.SpacerSmall />
                <StyledButton  onClick={() => airDropSubmit()}>Airdrop</StyledButton>
            </div>

            
            <s.SpacerLarge />
            <s.SpacerLarge />
            <hr style={{
                border: "10px solid green",
                borderRadius: "5px",
            }}/>
            <s.SpacerLarge />
            <h1
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                  }}
                >
                  SET BASE URI
                </h1>
            <p id = "pop-baseUri-text" > </p> 
            <s.SpacerLarge />
            <div >
            <input
            id = "setBaseURI" 
                        style={{
                            width: "50%",
                            padding: "12px 20px",
                            margin: "8px 0",
                            marginLeft: "25%",
                            boxSizing: "border-box",
                            borderRadius: "40px",
                          }}
                        placeholder="ipfs://NewURIHere/"
                        
                        />
                        <s.SpacerSmall />
            <StyledButton  onClick={() => SetBaseUriContract()}>Set Base Uri</StyledButton>
            </div>
            <s.SpacerLarge />

            <s.SpacerLarge />
            <s.SpacerLarge />
            <hr style={{
                border: "10px solid green",
                borderRadius: "5px",
            }}/>
            <s.SpacerLarge />
            <h1
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                  }}
                >
                  Revealed
                </h1>
            <p id = "pop-revealed-text" > </p> 
            <s.SpacerLarge />
            <div >
                <s.SpacerSmall />
            <StyledButton  onClick={() => SetRevealed()}>Revealed True</StyledButton>
            </div>
            <s.SpacerLarge />

            <s.SpacerLarge />
            <s.SpacerLarge />
            <hr style={{
                border: "10px solid green",
                borderRadius: "5px",
            }}/>
            <s.SpacerLarge />
            <h1
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                  }}
                >
                  SET NEW COST
                </h1>
            <p id = "pop-Cost-text" > </p> 
            <s.SpacerLarge />
            <div >
            <input
            id = "COST" 
                        style={{
                            width: "50%",
                            padding: "12px 20px",
                            margin: "8px 0",
                            marginLeft: "25%",
                            boxSizing: "border-box",
                            borderRadius: "40px",
                          }}
                        placeholder="COST IN WEI"
                        
                        />
                        <s.SpacerSmall />
            <StyledButton  onClick={() => SetCostContract()}>Set new Cost</StyledButton>
            </div>
            <s.SpacerLarge />

            </div> : null}


        </div>
        </div>
        </div>
    );


};

export default Admin;
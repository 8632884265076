import * as React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import img from "../assets/images/GamePass.png";

export default function FAQ() {
    return ( <Container className = "container"
        id = "team" >
        <h2 data-aos="fade-up"
     data-aos-duration="1000" >! TEAM !</h2> 
        <Grid container data-aos="fade-up"
     data-aos-duration="1000">
        <Grid item xs = {
            12
        }
        md = {
            4
        }
        lg = {
            4
        }
        className = "team-item-container" >
        <img className = "team-img"
        src = {
            img
        }
        alt = "Alien-Invaderz-Team" />
        <div className = "team-desc" >
        <h3 > Chris Campbell </h3> <p > Founder </p>
        <p > My name is Chris Campbell and I have been a physician assistant the last 8 years. I am a former high school science teacher and baseball coach. I love sports! I played division 2 baseball and am a bigger fan of basketball and football. I joined the cryptocurrency game a year ago mining Ethereum and then found my love of the NFT space. Being a sports enthusiast, I wanted to start my own NFT project based on sports. This is what has led me to where we are today and I am super excited for the future. </p>
         </div> </Grid>

        <Grid item xs = {
            12
        }
        md = {
            4
        }
        lg = {
            4
        }
        className = "team-item-container" >
        <img className = "team-img"
        src = {
            img
        }
        alt = "Alien-Invaderz-Team" />
        <div className = "team-desc" >
        <h3 > Dostiny </h3> <p > Community Manager </p> 
        <p > A double degree in environmental management by day and an extensive knowledge of technology and community management by night. Dostiny’s passion for NFT’s is relatively new however he has become quickly involved into the scene, managing NFT projects totaling hundreds of thousands of users. </p>
        </div> 
        </Grid> 
        <Grid item xs = {
            12
        }
        md = {
            4
        }
        lg = {
            4
        }
        className = "team-item-container" >
        <img className = "team-img"
        src = {
            img
        }
        alt = "Alien-Invaderz-Team" />
        <div className = "team-desc" >
        <h3 > Saige Newell </h3> <p > Community Manager </p> 
        <p > I am a traveling cook, soccer player and Blockchain enthusiast studying at saylor academy currently. My love for sports started as a kid playing Soccer, Basketball, and track since a young age and played soccer in college where I studied physical therapy. I'm very passionate about this space since my arrival and am looking forward to creating something great with Athlete Domains!! </p>
        </div> 
        </Grid>
        
        </Grid> 
        </Container>
    );
}
import React, { lazy } from "react";
import styled from "styled-components";


import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import image1 from "../assets/images/image1.jpg";
import image2 from "../assets/images/image2.jpg";
import image3 from "../assets/images/image3.jpg";
import image4 from "../assets/images/image4.jpg";

const handleDragStart = (e) => e.preventDefault();
const items = [
        <img src={image1} onDragStart={handleDragStart} role="presentation"/>,
        <img src={image2} onDragStart={handleDragStart} role="presentation"/>,
        <img src={image3} onDragStart={handleDragStart} role="presentation"/>,
        <img src={image4} onDragStart={handleDragStart} role="presentation"/>,

  ];


const Gallery = () => {

    

  return (
    <div  class="cd-main-content journey-style">
      <h2 data-aos = "fade-up"> ! GALLERY !</h2>
      <br/>
      <div>
        <AliceCarousel autoPlay autoPlayInterval="2000" 
        fadeOutAnimation={true}
        infinite= {true}
        mouseTracking items={items} 
        />
      </div>
    </div>
  );
};

export default Gallery;

import * as React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Discord from "../assets/images/discord.svg";
import Instagram from "../assets/images/instagram.svg";
import Youtube from "../assets/images/youtube.svg";
import Twitter from "../assets/images/twitter.png";
import Opensea from "../assets/images/opensea.svg";


export default function Footer() {
    return ( <Container className = "footer-container" >
        <Box className = "footer-box" >
        <div >
        <a className = "logo-text-footer" > Athlete Domains </a> </div> <p > All Rights Reserved 2022 </p> <p >
        {/* <a className = "terms-btn"
        onClick = {
            () => window.open(
                "/terms",
                "_blank"
            )
        } >
        Terms and conditions </a>  */}
        </p> 
        <p >
        <a className = "contract-btn"
        onClick = {
            () =>
            window.open(
                "https://etherscan.io/address/0x825d7da423ff393ea2e06a6305a06de47a753dd0",
                "_blank"
            )
        } >
        0x825d7da423ff393ea2e06a6305a06de47a753dd0 </a> </p> <div className = "footer-socials" >
        <img src = {
            Discord
        }
        onClick = {
            () =>
            window.open("https://discord.gg/2RvKC9weMu", "_blank")
        }
        /> <img src = {
            Youtube
        }
        onClick = {
            () =>
            window.open("https://youtu.be/cw9Vdbmuthw", "_blank")
        }
        /> <img src = {
            Twitter
        }
        onClick = {
            () =>
            window.open("https://twitter.com/athletedomains", "_blank")
        }
        /> <img src = {
            Opensea
        }
        onClick = {
            () =>
            window.open("https://opensea.io/collection/athlete-domains", "_blank")
        }
        /> 
        </div> 
        </Box> {/* <img src = {
            Bottom
        }
        className = "bottom" /> */}
        </Container>
    );
}
import React  from "react";

const Timeline = () => {

    return ( <div id = "roadmap">
        <h2 data-aos = "fade-up">! ROADMAP !</h2>

<div data-aos = "fade-up" class="cd-main-content journey-style">
        <div class="timeline">
	        <div class="line text-muted"></div>
	        <div class="year-time">2022 Quarter 2</div>
	        <article class="panel panel-danger panel-outline">
	            <div class="panel-heading icon">
	            </div>
	            <div class="panel-body">
	                <h3>
                        <time></time>
                        <p>Complete NFTworld #400 Athlete Domains build by verified builder Meraki Studios</p>
                    </h3>
	            </div>
	        </article>
	        <article class="panel panel-default panel-outline">
	            <div class="panel-heading icon">
	            </div>
	            <div class="panel-body">
	                <h3>
                        <time></time>
                        <p>Creation of 5000 NFT tickets to give holder future perks including $WRLD token rewards and 50% of ENS Domain name sales profits </p>
                    </h3>
	            </div>
	        </article>
	        <article class="panel panel-default panel-outline">
	            <div class="panel-heading icon">
	            </div>
	            <div class="panel-body">
	                <h3>
                        <time></time>
                        <p>Creation of minting website. </p>
                    </h3>
	            </div>
	        </article>
            <article class="panel panel-default panel-outline">
	            <div class="panel-heading icon">
	            </div>
	            <div class="panel-body">
	                <h3>
                        <time></time>
                        <p>Discord opens. </p>
                    </h3>
	            </div>
	        </article>
            <article class="panel panel-default panel-outline">
	            <div class="panel-heading icon">
	            </div>
	            <div class="panel-body">
	                <h3>
                        <time></time>
                        <p>WL mint is free (other than gas). </p>
                    </h3>
	            </div>
	        </article>
            <article class="panel panel-default panel-outline">
	            <div class="panel-heading icon">
	            </div>
	            <div class="panel-body">
	                <h3>
                        <time></time>
                        <p>General public mint 0.06 eth. </p>
                    </h3>
	            </div>
	        </article>
            <article class="panel panel-default panel-outline">
	            <div class="panel-heading icon">
	            </div>
	            <div class="panel-body">
	                <h3>
                        <time></time>
                        <p>ENS athlete domain names entered into community wallet. </p>
                    </h3>
	            </div>
	        </article>
            <article class="panel panel-default panel-outline">
	            <div class="panel-heading icon">
	            </div>
	            <div class="panel-body">
	                <h3>
                        <time></time>
                        <p>Purchase of more NFTworlds and ENS domain names to go into community wallet. $WRLD staking profits to be distributed among ticket holders </p>
                    </h3>
	            </div>
	        </article>
	        <div class="year-time1">2022 Quarter 3</div>
	        <article class="panel panel-default panel-outline date-extend">
	            <div class="panel-heading icon">
	            </div>
	            <div class="panel-body">
	                <h3>
                        <time></time>
                        <p>Creation and launch of multiple athlete-themed PVP P2E mini-games by Verified developer. </p>
                    </h3>
	            </div>
	        </article>
	        <article class="panel panel-default panel-outline date-extend">
	            <div class="panel-heading icon">
	            </div>
	            <div class="panel-body">
	                <h3>
                        <time></time>
                        <p>Securing the $WRLD faucet to allow players to earn $WRLD. </p>
                    </h3>
	            </div>
	        </article>
	        <article class="panel panel-default panel-outline date-extend">
	            <div class="panel-heading icon">
	            </div>
	            <div class="panel-body">
	                <h3>
                        <time></time>
                        <p>Creation of 5000 unique athlete-themed NFT’s with rarity scores. </p>
                    </h3>
	            </div>
	        </article>
            <article class="panel panel-default panel-outline date-extend">
	            <div class="panel-heading icon">
	            </div>
	            <div class="panel-body">
	                <h3>
                        <time></time>
                        <p>Purchasing more NFTworlds and ENS Domain names to go into community wallet. </p>
                    </h3>
	            </div>
	        </article>
            <article class="panel panel-default panel-outline date-extend">
	            <div class="panel-heading icon">
	            </div>
	            <div class="panel-body">
	                <h3>
                        <time></time>
                        <p>Building onto another NFTworld with a verified builder. </p>
                    </h3>
	            </div>
	        </article>
            <article class="panel panel-default panel-outline date-extend">
	            <div class="panel-heading icon">
	            </div>
	            <div class="panel-body">
	                <h3>
                        <time></time>
                        <p>Developing an in depth P2E game with verified developer. </p>
                    </h3>
	            </div>
	        </article>
            <article class="panel panel-default panel-outline date-extend">
	            <div class="panel-heading icon">
	            </div>
	            <div class="panel-body">
	                <h3>
                        <time></time>
                        <p>Distribution of $WRLD token staking rewards. </p>
                    </h3>
	            </div>
	        </article>
			 
	        
	    </div>
	</div>    
</div>
    );
};

export default Timeline;
import React, {
    useEffect,
    useState
} from "react";
import {
    Container,
    Box
} from "@mui/material/";
import Float from "../assets/images/float.png";
import Float2 from "../assets/images/float2.png";
import Float3 from "../assets/images/float3.png";
import Stash from "../assets/images/stash.png";
import Float5 from "../assets/images/float5.png";
import background from "../assets/images/bg-2.png";


const About = () => {
    const [offsetY, setOffsetY] = useState(0);
    const handleScroll = () => setOffsetY(window.pageYOffset);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return ( 
    <Container className = "container"
        id = "about"
        style = {
            {
                marginTop: 0
            }
        } >
        <img src = {
            Float
        }
        className = "floating-pickle float1"
        style = {
            {
                transform: `translateY(-${offsetY * 0.4}px) rotate(-20deg)`
            }
        }
        /> <img src = {
            Float2
        }
        className = "floating-pickle float2"
        style = {
            {
                transform: `translateY(-${offsetY * 0.25}px) rotate(0deg)`
            }
        }
        /> 
        <img src = {
            Float3
        }
        className = "floating-pickle float3"
        style = {
            {
                transform: `translateY(-${offsetY * 0.3}px) rotate(75deg)`
            }
        }
        />
        <img src = {
            Float5
        }
        className = "floating-pickle float5"
        style = {
            {
                transform: `translateY(-${offsetY * 0.3}px) rotate(75deg)`
            }
        }
        /> 
        <img src = {
            Stash
        }
        className = "floating-pickle float4"
        style = {
            {
                transform: `translateY(-${offsetY * 0.6}px) rotate(75deg)`
            }
        }
        />
        <div className = "about-title" >
        <h2 > !About! </h2>
        </div>

        <div >
        <Box data-aos = "fade-up"
        className = "welcome-box" >
        <div className = "welcome-img-container" > </div> 
        <div className = "welcome-content" >
        <p >
        Athlete Domains is an NFT project focused on all things relating to Sports! We have built a sports-themed metaverse on world #400 of NFTworlds. This consists of a large beach city containing many buildings, a large stadium, fighting arena, basketball courts, bowling alley, archery, volleyball courts, jet ski race, and football throw. Our goal is to have many pay and earn mini-games developed to allow our followers to have fun and earn the $wrld token! As we grow we plan to continue to develop our world with more games in the future.
        </p> 
        </div>

         </Box> 
         </div> 
         </Container>
    );
};

export default About;
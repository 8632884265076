import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Navbar from "./components/Navbar";

import Home from "./pages/Home";
import Admin from "./pages/Admin";
import AOS from 'aos';


function App(){
  AOS.init()
  return (
    <Router >
    <div >


      <Navbar/>
        <Switch >
        <Route exact path = "/" >
          <Home/> 
        </Route> 
      <Route exact path = "/admin" >
        <Admin/>
        </Route>
        </Switch> 



        </div>
      </Router> 


    
  );
}

export default App;

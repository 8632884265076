import React from "react";
import Minting from '../components/Minting';
import About from '../components/About';
import FAQ from '../components/FAQ';
import Roadmap from '../components/Roadmap';
import Footer from '../components/Footer';
import Team from "../components/Team";
import DevRoadmap from "../components/DevRoadmap";
import Gallery from "../components/Gallery";





function App(){
  return (
    <main style = {
        {
            overflowX: "hidden",
            width: "100%"
        }
    } >
      <Minting/>
      <About/>
      <Roadmap/>
      <Gallery/>
      <FAQ/>
      <Team/>
      <Footer/>

    </main>
  );
}

export default App;

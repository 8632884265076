import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import * as s from "../styles/globalStyles";
import styled from "styled-components";
import Countdown from "react-countdown";
import Grid from "@mui/material/Grid";
import background from "../assets/images/bg-2.png";
import Cloud1 from "../assets/images/cloud1.png";
import ufo2 from "../assets/images/ufo2.png";
import Cloud3 from "../assets/images/cloud3.png";
import bgmain from "../assets/images/bg-main.png";
//import alienship from "../assets/images/alienship.gif";
import alienship from "../assets/images/alienship.png";
import Whitelistaddresses from "../contract/whitelistaddresses.json";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
    
`;


export const StyledRoundButton = styled.button`
font-family: 'Fuzzy Bubbles', cursive;
  padding: 20px;
  border-radius: 4%;
  border: none;
  background-color: rgba(255, 255, 255, .4);
  font-weight: bold;
  font-size: 25px;
  color: white;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(119, 250, 108, 1);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(119, 250, 108, 1);
  -moz-box-shadow: 0px 4px 0px -2px rgba(119, 250, 108, 1);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  
`;


export const StyledLink = styled.a`
font-family: 'Fuzzy Bubbles', cursive;
  color: var(--secondary);
  text-decoration: none;
`;

function Minting() {

  const [showwhitelist, setshowwhitelist] = useState(true);


  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click mint to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    
    blockchain.smartContract.methods
      .mint(mintAmount)
      //.WhiteListMint(mintAmount)
      .send({
        //gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 2) {
      newMintAmount = 2;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };


  const checkWhitelistFunc = () => {
      for(var i = 0; i < Whitelistaddresses.whitelistadd.length; i++)
      {
        if(Whitelistaddresses.whitelistadd[i].toLowerCase() == window.ethereum.selectedAddress)
        {
          claimNFTs();
          console.log("found address",i);
        }
      } 
    
};


  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);



  const [offsetY, setOffsetY] = useState(0);
    const handleScroll = () => setOffsetY(window.pageYOffset);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);



  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed
}) => {
  if (completed) {
    // Render a complete state
    return (
      <div >
      <div className = "mintButton-container">
          <s.TextTitle
          style={{
            textAlign: "center",
            fontSize: 40,
            fontWeight: "bold",
            color: "var(--accent-text)",
          }}
        >
          {data.totalSupply} / 5000
          {/* {data.totalSupply} / {CONFIG.MAX_SUPPLY} */}
        </s.TextTitle>  
        <h4
          style={{
            textAlign: "center",
            color: "var(--primary-text)",
          }}
        >
         {/*  <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
            {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
          </StyledLink> */}
        </h4>
        <s.SpacerSmall />
        {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
          <>
            <s.TextTitle
              style={{ textAlign: "center", color: "var(--accent-text)" }}
            >
              The sale has ended.
            </s.TextTitle>
            <h4
              style={{ textAlign: "center", color: "var(--accent-text)" }}
            >
              You can still find {CONFIG.NFT_NAME} on
            </h4>
            <s.SpacerSmall />
            <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
              {CONFIG.MARKETPLACE}
            </StyledLink>
          </>
        ) : (
          <>
             <s.TextTitle
              style={{ textAlign: "center", color: "var(--accent-text)" }}
            >
              {/* 1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}
              {CONFIG.NETWORK.SYMBOL}. */}
              First 1000 free mint for WL.
            </s.TextTitle>
            <s.SpacerXSmall />
            <h4
              style={{ textAlign: "center", color: "var(--accent-text)" }}
            >
              Excluding gas fees.
            </h4>
            <s.SpacerSmall /> 
            {blockchain.account === "" ||
            blockchain.smartContract === null ? (
              <div ai={"center"} jc={"center"}>
                <h4
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                  }}
                >
                  Connect to the {CONFIG.NETWORK.NAME} network
                </h4>
                <s.SpacerSmall />
                <StyledButton className="pixel2"
                 //disabled={1}
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(connect());
                    getData();
                  }}
                >
                  <span className="pixel">CONNECT</span>
                </StyledButton>

                <s.SpacerMedium />
                <s.SpacerMedium />
                {blockchain.errorMsg !== "" ? (
                  <>
                    <s.SpacerSmall />
                    <h4
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {blockchain.errorMsg}
                    </h4>
                  </>
                ) : null}
              </div>
            ) : (
              <>
                <h4
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                  }}
                >
                  {feedback}
                </h4>

                <s.SpacerMedium />

                <s.SpacerMedium />
                <s.Container ai={"center"} jc={"center"} fd={"row"}>
                  <StyledRoundButton className="incr-decr"
                    style={{ lineHeight: 0.4 }}
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      decrementMintAmount();
                    }}
                  >
                    -
                  </StyledRoundButton>
                  <s.SpacerMedium />
                  <h3
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {mintAmount}
                  </h3>
                  <s.SpacerMedium />
                  <StyledRoundButton className="incr-decr"
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      incrementMintAmount();
                    }}
                  >
                    +
                  </StyledRoundButton>
                </s.Container>
                <s.SpacerSmall /> 



                <div ai={"center"} jc={"center"} fd={"row"}>
                  <StyledButton className="pixel2"
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      claimNFTs();
                      //checkWhitelistFunc();
                      getData();
                    }}
                  >
                    <span className="pixel">{claimingNft ? "BUSY" : "MINT"}</span>
                  </StyledButton>
                </div>
              </>
            )}
          </>
        )}
      </div>
      

    {/* <div >
      <h4
        style={{
          textAlign: "center",
          color: "var(--primary-text)",
        }}
      >
        Please make sure you are connected to the right network (
        {CONFIG.NETWORK.NAME} Mainnet) and the correct address. Please note:
        Once you make the purchase, you cannot undo this action.
      </h4>
      <s.SpacerSmall />
      <h4
        style={{
          textAlign: "center",
          color: "var(--primary-text)",
        }}
      >
        We have set the gas limit to {CONFIG.GAS_LIMIT} for the contract to
        successfully mint your NFT. We recommend that you don't lower the
        gas limit.
      </h4>
    </div> */}
  </div>
    );
  }
  else {
    // Render a countdown
    return ( <Grid container style = {
            {
                backgroundColor: "rgba(127, 96, 224, 0.4)",
                border: "solid 1px rgba(0,0,0,0.3)",
                padding: ".875rem",
            }
        }
        className = "coutdown-grid" >
        <Grid item xs = {
            3
        }
        className = "countdown-item countdown-border-right" >
        <p className = "countdown-value" > {
            days
        } </p> <p className = "countdown-text" > DAYS </p> </Grid> 
        <Grid item xs = {
            3
        }
        className = "countdown-item countdown-border-right" >
        <p className = "countdown-value" > {
            hours
        } </p> <p className = "countdown-text" > HRS </p> </Grid> <Grid item xs = {
            3
        }
        className = "countdown-item countdown-border-right" >
        <p className = "countdown-value" > {
            minutes
        } </p> <p className = "countdown-text" > MIN </p> </Grid> <Grid item xs = {
            3
        }
        className = "countdown-item" >
        <p className = "countdown-value" > {
            seconds
        } </p> <p className = "countdown-text" > SEC </p> </Grid> </Grid>
    );
    }
};

  return (

  <div style = {
            {
                width: "100vw",
                overflowX: "hidden"
            }
        } >
        <div className = "hero"
        style = {
            {
                backgroundImage: `url(${background})`,
            }
        } >

        <img src = {
            Cloud1
        }
        className = "hero-img cloud1 cloud" />
        <img src = {
            ufo2
        }
        className = "hero-img ufo2 cloud" />
        <img src = {
            Cloud3
        }
        className = "hero-img cloud3 cloud" />

      <div className = "jar" > {

        }  

        <img src = {
            bgmain
        }
        className = "hero-img bg-main" />
        <img src = {
            alienship
        }
        className = "hero-img alienship" />
        </div>
        <div className="countdown-container">
        <h3 style = {
            {
                fontSize: "30px"
            }
        } >
           Athlete Domains 
           </h3>
        <Countdown 
        date={
          // Date.parse('12 Feb 2022 21:00:00 GMT') PRE SALE ON February 12 th, 4 PM EST
          Date.parse('14 Feb 2022 02:00:00 GMT')
          //Date.parse('6 Feb 2022 02:00:00 GMT')
        }
         renderer={renderer} />
     

      </div>
        </div> 
        </div>
  );
}

export default Minting;

import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Container from "@mui/material/Container";

export default function FAQ() {
    return ( <Container className = "container"
        id = "faq" >
        <h2 
        data-aos="fade-up"
        data-aos-duration="1000" >! FAQ'S !</h2> 
        <div data-aos = "fade-up"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000">
         <br/>
         <br/>
        <div className = "accordionbg">
        <Accordion className = "accordion" >
        <AccordionSummary expandIcon = { <   ExpandMoreIcon
            style = {
                {
                    color: "white",
                }
            }
            />
        }
        aria-controls = "panel1a-content"
        id = "panel1a-header" >
        <h3 > Is your world built?  </h3> </AccordionSummary>
         <AccordionDetails >
        <p className = "accordion-details" >
        YES! We have a full beach city built on NFTworlds by verified builder Meraki Studios. This is a beautiful city that includes towering buildings, a large stadium, fighting arena, basketball courts, archery area, bowling alley, football throw, volleyball courts, and much more. 
         </p> 
         </AccordionDetails> 
         </Accordion> 
         <Accordion className = "accordion" >
        <AccordionSummary expandIcon = { <   ExpandMoreIcon
            style = {
                {
                    color: "white",
                }
            }
            />
        }
        aria-controls = "panel2a-content"
        id = "panel2a-header" >
        <h3 > How many NFT’s will mint? </h3> </AccordionSummary> <AccordionDetails >
        <p className = "accordion-details" >
        There will be 5000 total mint passes. They will be in the form of a sporting events ticket. 1000 tickets for whitelist/presale, 4000 tickets for public sale </p> 
        </AccordionDetails> 
        </Accordion> 
        <Accordion className = "accordion" >
        <AccordionSummary expandIcon = { <   ExpandMoreIcon
            style = {
                {
                    color: "white",
                }
            }
            />
        }
        aria-controls = "panel2a-content"
        id = "panel2a-header" >
        <h3 > When is Mint? </h3> </AccordionSummary> <AccordionDetails >
        <p className = "accordion-details" >
        While no date is set currently, we envision this will come in late May to mid June 2022 </p> 
        </AccordionDetails> 
        </Accordion> 
        <Accordion className = "accordion">
        <AccordionSummary expandIcon = { <   ExpandMoreIcon
            style = {
                {
                    color: "white",
                }
            }
            />
        }
        aria-controls = "panel2a-content"
        id = "panel2a-header" >
        <h3 > How much is Mint?</h3> </AccordionSummary> <AccordionDetails >
        <p className = "accordion-details" >
        WL mint is free (other than gas), 0.06 eth for public sale.</p> 
        </AccordionDetails> 
        </Accordion> 
        <Accordion className = "accordion" >
        <AccordionSummary expandIcon = { <   ExpandMoreIcon
            style = {
                {
                    color: "white",
                }
            }
            />
        }
        aria-controls = "panel2a-content"
        id = "panel2a-header" >
        <h3 > What is ENS and these athlete names that will go into the community wallet? </h3> </AccordionSummary> <AccordionDetails >
        <p className = "accordion-details" >
        ENS stands for Ethereum Name Service. This company sells .eth names. The basic utility is you can set your wallet to a specific name you purchase. <br/>
        For instance, instead of having the super long wallet address; you can have athletedomains.eth instead. There is so many more utilities, but this is the most obvious!
        </p> 
        </AccordionDetails> 
        </Accordion> 
        <Accordion className = "accordion" >
        <AccordionSummary expandIcon = { <   ExpandMoreIcon
            style = {
                {
                    color: "white",
                }
            }
            />
        }
        aria-controls = "panel2a-content"
        id = "panel2a-header" >
        <h3 > How did you get the ENS names? </h3> </AccordionSummary> <AccordionDetails >
        <p className = "accordion-details" >
        The ones collected so far have come from the founder’s own funds. He is going to put all of these into the community wallet. Then use mint profits to buy more in the future. The community can research available names and make requests for purchase.
        </p> 
        </AccordionDetails> 
        </Accordion> 
        <Accordion className = "accordion" >
        <AccordionSummary expandIcon = { <   ExpandMoreIcon
            style = {
                {
                    color: "white",
                }
            }
            />
        }
        aria-controls = "panel2a-content"
        id = "panel2a-header" >
        <h3 > So the plan is to sell the athlete ENS names? </h3> </AccordionSummary> <AccordionDetails >
        <p className = "accordion-details" >
        YES! As a community, we will twitter raid and market like crazy. If we get someone interested, agree on a price, we will sell, and split profits with 50% back to the community. We may convert the Eth to $Wrld and airdrop. This is still pending.
        </p> 
        </AccordionDetails> 
        </Accordion> 
        <Accordion className = "accordion" >
        <AccordionSummary expandIcon = { <   ExpandMoreIcon
            style = {
                {
                    color: "white",
                }
            }
            />
        }
        aria-controls = "panel2a-content"
        id = "panel2a-header" >
        <h3 > Will there be a future NFT collection other than the tickets? </h3> </AccordionSummary> <AccordionDetails >
        <p className = "accordion-details" >
        Yes! We plan on having a second mint collection of 5000. This will have traits and rarity. We will develop 3D athletes in different sports. The rarity will focus on race, sex, sexuality, ethnicity, ect. This will promote an INCLUSIVE environment to include EVERYONE. We support equality and diversity and want to show this in our second mint. LOVE is LOVE
        </p> 
        </AccordionDetails> 
        </Accordion> 
        </div> 
        </div>
        </Container>
    );
}
